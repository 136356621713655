// src/pages/cv.jsx
import React from 'react';
import Layout from '../components/layout';
import Header from '../components/header';
import SEO from '../components/seo';
import { graphql, useStaticQuery } from 'gatsby';

const CvPage = () => {
  // Use GraphQL query to fetch the metadata
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          name
          title
          description
          medium
          github
          linkedin
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Alaa's CV" />
      {/* Add the Header with the fetched metadata */}
      <Header metadata={data.site.siteMetadata} />
      <h1 style={{ paddingBottom: '20px' }}>Research Proposal</h1>
      <div style={{ height: '80vh', width: '100%' }}>
        <iframe
          src="/research proposal .pdf"
          width="100%"
          height="100%"
          title="CV"
          style={{ border: 'none' }}
        />
      </div>
    </Layout>
  );
};

export default CvPage;
